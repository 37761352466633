import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { colorPalette } from 'ui/theme';

export const ErrorFallback = (props: any) => {
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: colorPalette.redesign.background3,
        padding: 0,
        margin: '-8px',
      }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          margin: 0,
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '48px',
        }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h4"
          sx={{ paddingBottom: '24px' }}
          fontWeight="600"
        >
          Oops! Something went wrong :(
        </Typography>
        <Typography variant="body2">{props?.error?.message}</Typography>
        <Typography
          variant="body2"
          sx={{ paddingTop: '12px' }}
          fontWeight="600"
        >
          Please refresh your browser and try again
        </Typography>
      </Box>
    </Box>
  );
};
